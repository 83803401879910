/** Handles the size of the container for mobile and desktop */

/** Mobile */
@media screen and (max-width: 800px) {
  .examples-container {
    margin-left: 25px;
    margin-right: 25px;
  }
  .navigation-burger {
    position: fixed;
    cursor: pointer;
    display: block;
    right: 15px;
    top: 15px;
    width: 40px;
    height: 40px;
  }
  .navigation-container {
    position: fixed;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 100;
  }
  .navigation-container.active {
    position: fixed;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 300px;
    background-color: rgb(17, 17, 43);
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 100;
  }
}
/** Desktop */
@media screen and (min-width: 800px) {
  .examples-container {
    margin-left: 260px;
    margin-right: 200px;
  }
  .navigation-burger {
    display: none;
  }
  .navigation-container {
    position: fixed;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 230px;
  }
}

/** Manage code format */
pre {
  color: #ffffff !important;
  margin-bottom: 0px !important;
  background: rgba(0, 0, 0, 0.25);
  padding: 25px;
  white-space: pre-wrap;
}
hr {
  width: 50%;
  border-color: rgba(255, 255, 255, 0.2) !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
ul,
li {
  color: #ffffff;
}
.component_properties ul {
  margin-top: 10px !important;
}
.component_properties li {
  margin-bottom: 5px;
}

.bubble {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  border: 1px solid #322f3a;
  display: inline-block;
  margin-right: 7px;
  vertical-align: middle;
}

/** Custom Properties */
.TextHover:hover {
  cursor: pointer;
  text-decoration: underline;
}
/** Utilities */
.align_middle {
  vertical-align: middle !important;
}
.white {
  color: #ffffff;
}
.grey {
  color: #6a668e;
}
.blue {
  color: #29cae4;
}
.yellow {
  color: #fdb640;
}
.pink {
  color: #e74b86;
}
.green {
  color: #7ffacc;
}
.red {
  color: #e85e5e;
}
.inline_block {
  display: inline-block;
}
.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
.smallcaps {
  text-transform: lowercase;
  font-variant: small-caps;
}

.left {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}

.cursor {
  cursor: pointer;
}

.p-10 {
  padding: 10px !important;
}

.pleft-5 {
  padding-left: 5px;
}
.pleft-10 {
  padding-left: 10px;
}
.pleft-15 {
  padding-left: 15px;
}
.pleft-20 {
  padding-left: 20px;
}

.pright-5 {
  padding-right: 5px;
}
.pright-10 {
  padding-right: 10px;
}
.pright_15 {
  padding-right: 15px;
}
.pright-20 {
  padding-right: 20px;
}

.ptop-5 {
  padding-top: 5px;
}
.ptop-10 {
  padding-top: 10px;
}
.ptop-15 {
  padding-top: 15px;
}
.ptop-20 {
  padding-top: 20px;
}
.ptop-25 {
  padding-top: 25px;
}
.ptop-50 {
  padding-top: 50px;
}
.ptop-75 {
  padding-top: 75px;
}

.mright-5 {
  margin-right: 5px !important;
}
.mright-10 {
  margin-right: 10px !important;
}
.mright-15 {
  margin-right: 15px !important;
}

.mtop-10 {
  margin-top: 10px !important;
}
.mtop-15 {
  margin-top: 15px !important;
}
.mtop-20 {
  margin-top: 20px !important;
}
.mtop-25 {
  margin-top: 25px !important;
}
.mtop-30 {
  margin-top: 30px !important;
}
.mtop-40 {
  margin-top: 40px !important;
}

.mbottom-10 {
  margin-bottom: 10px !important;
}
.mbottom-15 {
  margin-bottom: 15px !important;
}
.mbottom-20 {
  margin-bottom: 20px !important;
}
.mbottom-25 {
  margin-bottom: 25px !important;
}
.mbottom-50 {
  margin-bottom: 50px !important;
}
